

.overflow{
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */

}

.principal img{
  width: 30vh;
}

.principal{
  background: linear-gradient(to bottom right, #336a63 50%, #fcf4ed 50%);
  padding: 2vh 2vh;
  padding-bottom: 0px;

}

.homeEnd{
  height: 15vh;
  background-color: #fcf4ed;
}
.end{
  background-color: #fcf4ed;
}
.triangle{
  height: 20vh;
  background: linear-gradient(to top left, #336a63 50%, #fcf4ed 50%);
}

.center{
  display: flex;
  align-items: center;
}

.title h1{
  font-family:fantasy;
  font-size: 500%;
  color:coral;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;

}
.title h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 200%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.options{
  padding-top: 2vh;
  padding-left: 5vh;
  padding-right: 5vh;
  justify-content: center;
  background-color: #fcf4ed;
  height: 55vh;
}

.setMargin{
  transition: 0.3s;
  margin: 2vh 2vh;
  cursor: pointer;
}

.setMargin:hover{
  transition: 0.3s;
  scale: 1.03;
  margin: 2vh 2vh;
  cursor: pointer;
}

.recipiesNShop{
  background-image: url("images/beer.webp");
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vh;
}
.inventory{
  background-image: url("images/Ingredients.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vh;
}
.events{
  background-image: url("images/cheers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 30vh;
}
.text{
  padding-top: 1.5vh;
}
.text h5{
  text-align: center;
  color: #336a63;
}
.text p{
  padding-top: 1vh;
  margin: -1vh;
  text-align: center;
  color: #694830;
}

