.image{
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.beer{
  padding: 4vh 4vh;
  background-color: #eed6bf;
  border: 2vh solid #fcf4ed;

}

.beerName{
  padding-top: 1vh;
  text-align: center;
  font-size: 140%;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:  #207178;
}

.beerDescription{
  padding-top: 0.5vh;
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: black;
}
.beerCar{
  padding-top: 0.5vh;
  font-size: 80%;
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: black;
}
.beerDiv{
  cursor: pointer;
}

