.principal img{
  width: 20vh;
  padding-top: 2vh;
}

.modal90{
  width: 90vw;
  max-width: 90vw !important;
}

.textBox{
  width: 100%;
  position: relative;
  padding: 12px;
  box-sizing: border-box;
  resize: none;
}


.image{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 10vw !important;
  padding-bottom: 2% ;
}

.text{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  padding-right: 10vw !important;
}


.title h1{
  font-family:fantasy;
  font-size: 300%;
  color:coral;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}
.title h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 175%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.overflow{
  -ms-overflow-style: none;  /* IE and Edge */
  height: 100%;
  background-color: #fcf4ed;
}

.padding{
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 10vh;
}

.paddingText{
  padding-left: 5vw;
  padding-right: 5vw;
}

.paddingSearches{
  text-align: end;
  padding: 2vh 2vw;
}

.input{
  text-align: left; 
}

input::placeholder {
  text-align: left; 
}

.searchCity{
  text-align: end;
}

.discover{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 175%;
  color:  black;
  padding-left: 5vw !important;
}

.useLocation{
  width: 75%;
  height: 100%;
  color: white;
  font-size: 85%;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: rgb(48, 45, 45);
  border-radius: 5px;
  cursor: pointer;
}

.popup{
  padding-top: 2vh;
  justify-content: center;
}

.popup button{
  cursor: pointer;
}

.popUpGroup{
  padding: 2vh 0;
}

.border{
  border: 1px solid black;
  margin-left: calc(var(--bs-gutter-x) * .5);
}
.padd{
  background-color: #D3D3D3;
}

.centerEventList{
  display: flex;
  align-items: center;
}

.addButton {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  position: relative;
  cursor: pointer;
}
.addButton::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: black;
  height: 5px;
  margin-top: -2.5px;
  top: 50%;
  left: 2.5px;
  right: 2.5px;
  z-index: 4.5;
}
.addButton::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: black;
  width: 5px;
  margin-left: -2.5px;
  left: 50%;
  top: 2.5px;
  bottom: 2.5px;
  z-index: 4.5;
}