

.principal img{
  width: 20vh;
  padding-top: 2vh;
}

.principal{
  padding: 10vh 10vw;
}

.image{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 10vw;
  padding-bottom: 2%;
}

.text{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  padding-right: 10vw;
}


.title h1{
  font-family:fantasy;
  font-size: 300%;
  color:coral;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;

}
.title h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 175%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.images{
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.car{
  height: 25vh;
  background-repeat: no-repeat;
}

.align{
  padding: 5vh 5vw;
  text-align: center;
  background-color:  #eed6bf;
}

.back{
  border: 10vw solid #fcf4ed;
  border-bottom: 0vw;
  border-top: 0vw;
}

.carateristics{
  padding: 10vh 0vw;
  padding-bottom: 2vh;
  text-align: center;
}

.all{
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #fcf4ed;

}

.ingredients{
  padding: 10vh 0vw;
  padding-bottom: 0vh;
  text-align: left;
}

.procedure{
  padding: 10vh 0vw;
  text-align: left;

}

.padding{
  padding: 10vh 0vw;
  text-align: center;
}

.paddingComments{
  padding: 10vh 0vw;
  padding-left: 10vw;
}

.paddingBottom{
  padding-bottom: 0vh;
}

.buy{
  padding-left: 12vw;
  padding-right: 12vw;

}

.color{
  padding-top: 5vh;
  text-align: center;  
  background-color: #207178;
  border-radius: 12px;

}

.buyButton{
  padding-bottom: 5vh;
  padding-top: 2vh;
  display: flex;
  justify-content: center;
}

.buyButton button{
  border-radius: 12px;
  color: white;
  background-color: #0e2f32;
  cursor: pointer;
}

.commentsColor{
  background-color:  #eed6bf;
  border-radius: 12px;
  padding: 2vh 2vw;
}

.price{
  padding-top: 2vh;
}

.buyButton2{
  border-radius: 12px;
  color: white;
  background-color: #0e2f32;
  cursor: pointer;
  width: 50%;
}

.imageBuy{
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}

.padd{
  padding: 2vh 2vw;
}

.Button{
  border-radius: 12px;
  color: white;
  background-color: #0e2f32;
  cursor: pointer;
  width: 50%;
}

.but{
  display: flex;
  justify-content: center;
  align-items: center;
}