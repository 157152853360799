.beersRecipes {
  min-height: 100vh;
  padding-left: 1vw;
  overflow-x: hidden;
}

.principal{
  padding: 0vh 10vw;
}

.filter {
  display: flex;
  padding-top: 10vh;
  justify-content: center;
}

.up{
  padding-bottom: 1vh;
}

.warehouse {
  background-color: #fcf4ed;
}

#dropdown {
  width: 15vw;
  background-color: #ededed;
  color: black;
  border: 0px;
}

#dropdown:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  border: 0px;
}

.dropdownItem {
  border: 0px;
}

.dropdownItem:active {
  color: black;
  background-color:#fcf4ed;
}

.style {
  padding-bottom: 20vh;
}

.principal img{
  width: 20vh;
  padding-top: 2vh;
}


.image{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 10vw;
  padding-bottom: 2%;
}

.text{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  padding-right: 10vw;
}


.title h1{
  font-family:fantasy;
  font-size: 300%;
  color:coral;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;

}
.title h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 175%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

}

.search{
  padding: 10vh 10vw;
  padding-top: 5vh;
}

.search h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 175%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.searchProps{
  padding-top: 3vh;
  padding-bottom: 5vh;
}

#dropdown2 {
  width: 15vw;
  background-color: white;
  color: black;
  border: 0px;
}

#dropdown2:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  border: 0px;
}

.beerExamples::-webkit-scrollbar {
  width: 0.5vw;
}

.beerExamples::-webkit-scrollbar-track {
  background-color: #824d1f;
}

.beerExamples::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px #db9759;
}