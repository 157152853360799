.inventory{
  padding: 5vh 20vh;
  color: black;
  background-color: #fcf4ed;
  min-height: 100vh;
}
.ingredientList{
  padding-top: 2.5%;
}
.table{
  text-align: center;
  height: 6vh;
}

.colorCol{
  background-color: grey;
}

.round{
  padding: 2vh 2vw;
}
.col{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 0.5vw solid #ede8e7;
  border-right: 0.25vw solid #ede8e7;
}

.row{
  background-color: #ede8e7;
  text-align: center;
}

.textInput{
  width: 100%;
  border: 0.5vw solid #ede8e7;
  border-left: 0.25vw solid #ede8e7;
  text-align: center;
}

.addButton {
  background-color: #fcf4ed;
  border: 0px;
  width: 100%;
  cursor: pointer;
}

.category{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #207178;
  padding-left: 0.5vw;
}

.invisible{
  background-color: #fcf4ed;
}


.removeButton{
  background-color: #ede8e7;
  border: 0px;
  width: 2vw;
  max-height: 5vh;
  cursor: pointer;
  transform: translate(-1.5vw);
}

.styleCategory{
  padding: 5vh 2vw;
  border: 1vw solid #fcf4ed;
}

.padding{
  padding-top: 2vh;
}

.paddingADD{
  transform: translate(0, -5px);
}

.title h1{
  font-family:fantasy;
  font-size: 450%;
  color:coral;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;

}
.title h2{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 200%;
  color:  #207178;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.title{
  padding-bottom: 7.5vh;
}

.centerTitle{
  display: flex;
  justify-content: center;
}

.header{
  display: flex;
  justify-content: center;
}

.textStyle{
  display: flex;
  align-items: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:  #207178;
}

.center{
  text-align: center;
  padding-top: 2vh;
}
input::placeholder{
  text-align: left;
}
.padd{
  padding-top: 2vh;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color:  #207178;
}

#dropdown {
  background-color: white;
  color: black;
  border: 0px;
  width: 100%;
}

#dropdown:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  border: 0px;
}

.dropdownItem {
  border: 0px;
}

.dropdownItem:active {
  color: black;
  background-color:#207178;
}

.save{
  display: flex;
  justify-content: flex-end;
  padding-right: 1vw;
  padding-top: 4vh;
}

.saveButton{
  border-radius: 12px;
  width: 25%;
  background-color: #a2a1a3;
  color: white;
  height: 5vh;
  max-height: 50px;
  min-height: 30px;
  cursor: pointer;

}

.advice{
  color:#a2a1a3;
}

.advice2{
  color:#848286;
}